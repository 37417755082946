import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import AjaxLoad from '../../utilities/js/ajax-load';
import './quick-start.scss';

class QuickStart {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-quickstart',
            countAttr: 'data-quickstart-count-ajax',
            modalAttr: 'data-quickstart-modal',
            scrollerValue: 'scroller',
            contentValue: 'content',
            selectorValue: 'selector',
            selectionValue: 'selection',
            closeButtonValue: 'close',
            confirmButtonValue: 'confirm',
            deselectButtonValue: 'deselect',
            selectButtonValue: 'select',
            errorValue: 'error',
            selectionActiveAttr: 'data-quickstart-selection-active'
        };

        // Merge settings
        this.settings = Object.assign({}, defaults, options);

        // DOM Elements
        this.$root = element;
        this.$modalScroller = this.$root.querySelector(`[${this.settings.initAttr}="${this.settings.scrollerValue}"]`);
        this.$form = this.$root.querySelector('form');
        this.$submitButton = this.$root.querySelector('button[type="submit"]');
        this.$counter = this.$submitButton?.querySelector('span');
        this.$content = this.$root.querySelector(`[${this.settings.initAttr}="${this.settings.contentValue}"]`);
        this.$$selectors = this.$root.querySelectorAll(`[${this.settings.initAttr}="${this.settings.selectorValue}"]`);
        this.$$selections = this.$root.querySelectorAll(`[${this.settings.initAttr}="${this.settings.selectionValue}"]`);
        this.$$closeButtons = this.$root.querySelectorAll(`[${this.settings.initAttr}="${this.settings.closeButtonValue}"]`);
        this.$$confirmButtons = this.$root.querySelectorAll(`[${this.settings.initAttr}="${this.settings.confirmButtonValue}"]`);
        this.$$deselectButtons = this.$root.querySelectorAll(`[${this.settings.initAttr}="${this.settings.deselectButtonValue}"]`);
        this.$$selectButtons = this.$root.querySelectorAll(`[${this.settings.initAttr}="${this.settings.selectButtonValue}"]`);

        // Options
        this.countAjax = this.$root.getAttribute(this.settings.countAttr);
        this.visibleSelectionIndex = 0;
        this.selectedCheckboxes = {};
        this.selectorLabels = [];

        if (this.$content) {
            this.initialize();
        }
    }

    initialize () {
        this.checkResultCount();

        this.$$selectors.forEach($selector => {
            const $span = $selector.querySelector('span');

            if ($span) {
                this.selectorLabels.push($span.textContent);
            }
        });

        this.setEvents();
    }

    checkResultCount () {
        this.result = new AjaxLoad(this.countAjax, {
            requestMethod: 'post',
            requestData: new FormData(this.$form),
            onSuccess: (result) => {
                const parsedResult = JSON.parse(result);
                this.$$confirmButtons.forEach($confirmButton => {
                    $confirmButton.disabled = parsedResult.count === 0;
                });

                if (parsedResult.count === 0) {
                    this.$root.classList.add('quick-start--has-error');
                    const $errorMessage = this.$$selections[this.visibleSelectionIndex].querySelector(`[${this.settings.initAttr}="${this.settings.errorValue}"]`);

                    if ($errorMessage && window.innerWidth < 1024) {
                        this.$content.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    }
                } else {
                    this.$root.classList.remove('quick-start--has-error');
                }

                if (this.$counter) {
                    this.$counter.textContent = `(${parsedResult.count})`;
                }
            },
            onError: (error) => {
                console.warn(error);
            }
        });
    }

    calculateSelectionHeight () {
        const rootHeight = this.$root.offsetHeight;
        const firstSelectionHeight = this.$$selections[0].offsetHeight;
        const newHeight = rootHeight - firstSelectionHeight + this.$$selections[this.visibleSelectionIndex].offsetHeight;
        this.$root.style.setProperty('--form-height', `${newHeight}px`);
    }

    toggleSelectionVisibility () {
        this.$root.setAttribute(this.settings.modalAttr, this.visibleSelectionIndex !== 0);
        this.calculateSelectionHeight();

        this.$$selections.forEach(($selection, index) => {
            $selection.setAttribute(this.settings.selectionActiveAttr, this.visibleSelectionIndex === index);
        });

        this.toggleBodyScroll();
    }

    toggleBodyScroll () {
        if ((this.$modalScroller && window.innerWidth >= 1024) || this.visibleSelectionIndex === 0) {
            enableBodyScroll(this.$modalScroller);
        }

        if (this.visibleSelectionIndex > 0 && window.innerWidth < 1024) {
            disableBodyScroll(this.$modalScroller);
        }
    }

    updateSelect () {
        const $$inputs = this.$form.querySelectorAll('input[type="checkbox"]');

        $$inputs.forEach($input => {
            const value = $input.value;
            const $relatedOption = this.$root.querySelector(`option[value="${value}"]`);

            if ($relatedOption) {
                if ($input.checked) {
                    $relatedOption.setAttribute('selected', 'selected');
                } else {
                    $relatedOption.removeAttribute('selected');
                }
            }
        });
    }

    setEvents () {
        this.$$selectors.forEach(($selector, index) => {
            $selector.addEventListener('click', () => {
                this.visibleSelectionIndex = index + 1;
                this.toggleSelectionVisibility();
            });
        });

        this.$$closeButtons.forEach(($closeButton) => {
            $closeButton.addEventListener('click', () => {
                const $$inputs = this.$$selections[this.visibleSelectionIndex].querySelectorAll('input[type="checkbox"]');

                $$inputs.forEach(($input) => {
                    $input.checked = this.selectedCheckboxes[this.visibleSelectionIndex]?.includes($input.value) || false;
                });

                this.visibleSelectionIndex = 0;
                this.toggleSelectionVisibility();
                this.updateSelect();
                this.checkResultCount();
            });
        });

        this.$$confirmButtons.forEach(($confirmButton) => {
            $confirmButton.addEventListener('click', () => {
                const $$dates = [...this.$$selections[this.visibleSelectionIndex].querySelectorAll('input[type="date"]')];
                const $$checkboxes = [...this.$$selections[this.visibleSelectionIndex].querySelectorAll('input[type="checkbox"]')];
                const $span = this.$$selectors[this.visibleSelectionIndex - 1].querySelector('span');
                const labels = [];

                this.selectedCheckboxes[this.visibleSelectionIndex] = $$checkboxes
                    .filter(input => input.checked)
                    .map(input => input.value) || [];

                if ($span) {
                    if ($$checkboxes.length > 0) {
                        $$checkboxes.forEach($checkbox => {
                            if ($checkbox.checked) {
                                const $label = $checkbox.parentNode.querySelector('label');

                                if ($label) {
                                    labels.push($label.textContent.trim());
                                }
                            }
                        });

                        if (labels.length > 0) {
                            $span.textContent = labels.join(', ');
                        } else {
                            $span.textContent = this.selectorLabels[this.visibleSelectionIndex - 1];
                        }
                    } else if ($$dates.length > 0) {
                        $$dates.forEach($selectedDate => {
                            if ($selectedDate.value !== '') {
                                labels.push($selectedDate.value);
                            }
                        });

                        if (labels.length === 2) {
                            const formattedDates = labels
                                .map(date => {
                                    console.log(date);
                                    const newDate = new Date(date);
                                    return newDate.toLocaleDateString(navigator.language);
                                })
                                .join(' - ');

                            $span.textContent = formattedDates;
                        } else {
                            $span.textContent = this.selectorLabels[this.visibleSelectionIndex - 1];
                        }
                    }
                }

                this.visibleSelectionIndex = 0;
                this.toggleSelectionVisibility();
            });
        });

        this.$$deselectButtons.forEach(($deselectButton) => {
            $deselectButton.addEventListener('click', () => {
                const $$$checkboxes = this.$$selections[this.visibleSelectionIndex].querySelectorAll('input[type="checkbox"]');

                $$$checkboxes.forEach($$checkbox => {
                    $$checkbox.checked = false;
                });

                this.updateSelect();
                this.checkResultCount();
            });
        });

        this.$$selectButtons.forEach(($selectButton) => {
            $selectButton.addEventListener('click', () => {
                const $$inputs = this.$$selections[this.visibleSelectionIndex].querySelectorAll('input[type="checkbox"]');
                $$inputs.forEach($input => {
                    $input.checked = true;
                });
                this.updateSelect();
                this.checkResultCount();
            });
        });

        window.resizeHandler.customFunctions.push(() => {
            this.toggleBodyScroll();
            this.calculateSelectionHeight();
        });

        if (this.$form) {
            this.$form.addEventListener('submit', (e) => {
                e.preventDefault();
                this.$form.submit();
            });

            this.$form.addEventListener('change', (e) => {
                this.updateSelect();
                this.checkResultCount();
            });
        }
    }
}

export { QuickStart };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-quickstart="root"]');
        $$roots.forEach($root => {
            new QuickStart($root); // eslint-disable-line
        });
    }
});
